var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Position","code-toggler":""}},[_c('p',[_vm._v("We can change the place where the notification appears with the property position. A practical example would be: position: 'top-right'. The supported values are")]),_c('vx-list',{staticClass:"mt-3",attrs:{"list":_vm.listPosition}}),_c('vs-alert',{staticClass:"mb-1",attrs:{"icon":"warning","active":"true","color":"warning"}},[_c('span',[_vm._v("The default notifications appear "),_c('code',[_vm._v("bottom-right")]),_vm._v(", it is not necessary to add the position property if it is the desired position")])]),_c('div',{staticClass:"demo-alignment"},[_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Position Default',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'primary'})}}},[_vm._v("Position Default ")]),_c('vs-button',{attrs:{"color":"success","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Position top-right',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'success',
                  position:'top-right'})}}},[_vm._v("Position Top Right ")]),_c('vs-button',{attrs:{"color":"danger","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Position top-left',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'danger',
                  position:'top-left'})}}},[_vm._v("Position Top Left ")]),_c('vs-button',{attrs:{"color":"warning","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Position bottom-left',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'warning',
                  position:'bottom-left'})}}},[_vm._v("Position Bottom Left ")]),_c('vs-button',{attrs:{"color":"dark","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Position bottom-center',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'dark',
                  position:'bottom-center'})}}},[_vm._v(" Position Bottom Center ")]),_c('vs-button',{attrs:{"color":"success","type":"gradient"},on:{"click":function($event){return _vm.randomCenter()}}},[_vm._v("Position Top Center Random Color")])],1),_c('template',{slot:"codeContainer"},[_vm._v(" <template> <div class=\"demo-alignment\"> <vs-button color=\"primary\" type=\"flat\" @click=\"$vs.notify({ title:'Position Default', text:'Lorem ipsum dolor sit amet, consectetur', color:'primary'})\">Position Default</vs-button> <vs-button color=\"success\" type=\"flat\" @click=\"$vs.notify({ title:'Position top-right', text:'Lorem ipsum dolor sit amet, consectetur', color:'success', position:'top-right'})\">Position Top Right</vs-button> <vs-button color=\"danger\" type=\"flat\" @click=\"$vs.notify({ title:'Position top-left', text:'Lorem ipsum dolor sit amet, consectetur', color:'danger', position:'top-left'})\">Position Top Left</vs-button> <vs-button color=\"warning\" type=\"flat\" @click=\"$vs.notify({ title:'Position bottom-left', text:'Lorem ipsum dolor sit amet, consectetur', color:'warning', position:'bottom-left'})\">Position Bottom Left</vs-button> <vs-button color=\"dark\" type=\"flat\" @click=\"$vs.notify({ title:'Position bottom-center', text:'Lorem ipsum dolor sit amet, consectetur', color:'dark', position:'bottom-center'})\"> Position Bottom Center</vs-button> <vs-button @click=\"randomCenter()\" color=\"success\" type=\"gradient\">Position Top Center Random Color</vs-button> </div> </template> <script> export default { methods: { randomCenter() { function getRandomInt(min, max) { return Math.floor(Math.random() * (max - min)) + min; } let color = `rgb(${getRandomInt(0,255)},${getRandomInt(0,255)},${getRandomInt(0,255)})` this.$vs.notify({ title: 'Position top-center', text: 'Lorem ipsum dolor sit amet, consectetur', color: color, position: 'top-center' }) } } } </script> ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }