var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Default","code-toggler":""}},[_c('p',[_vm._v("To add a notification we have the global function "),_c('code',[_vm._v("$vs.notify")]),_vm._v(". The first parameter is a "),_c('code',[_vm._v("json")]),_vm._v(" with all the necessary attributes, or if we only want the standard notification we can only add a string with the text of the notification")]),_c('div',{staticClass:"demo-alignment"},[_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Primary',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'primary'})}}},[_vm._v("Notification Primary ")]),_c('vs-button',{attrs:{"color":"success","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Success',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'success'})}}},[_vm._v("Notification Success ")]),_c('vs-button',{attrs:{"color":"danger","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Danger',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'danger'})}}},[_vm._v("Notification Danger ")]),_c('vs-button',{attrs:{"color":"warning","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'Warning',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'warning'})}}},[_vm._v("Notification Warning ")]),_c('vs-button',{attrs:{"color":"dark","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
                  title:'dark',
                  text:'Lorem ipsum dolor sit amet, consectetur',
                  color:'dark'})}}},[_vm._v("Notification Dark ")]),_c('vs-button',{attrs:{"color":"success","type":"gradient"},on:{"click":function($event){return _vm.random()}}},[_vm._v("Notification Random Color")])],1),_c('template',{slot:"codeContainer"},[_vm._v(" <template> <div class=\"demo-alignment\"> <vs-button @click=\"$vs.notify({ title:'Primary', text:'Lorem ipsum dolor sit amet, consectetur', color:'primary'})\" color=\"primary\" type=\"flat\">Notification Primary</vs-button> <vs-button @click=\"$vs.notify({ title:'Success', text:'Lorem ipsum dolor sit amet, consectetur', color:'success'})\" color=\"success\" type=\"flat\">Notification Success</vs-button> <vs-button @click=\"$vs.notify({ title:'Danger', text:'Lorem ipsum dolor sit amet, consectetur', color:'danger'})\" color=\"danger\" type=\"flat\">Notification Danger</vs-button> <vs-button @click=\"$vs.notify({ title:'Warning', text:'Lorem ipsum dolor sit amet, consectetur', color:'warning'})\" color=\"warning\" type=\"flat\">Notification Warning</vs-button> <vs-button @click=\"$vs.notify({ title:'dark', text:'Lorem ipsum dolor sit amet, consectetur', color:'dark'})\" color=\"dark\" type=\"flat\">Notification Dark</vs-button> <vs-button @click=\"random()\" color=\"success\" type=\"gradient\">Notification Random Color</vs-button> </div> </template> <script> export default { methods: { random() { function getRandomInt(min, max) { return Math.floor(Math.random() * (max - min)) + min; } let color = `rgb(${getRandomInt(0,255)},${getRandomInt(0,255)},${getRandomInt(0,255)})` this.$vs.notify({ title: 'Color', text: 'Lorem ipsum dolor sit amet, consectetur', color: color }) }, } } </script> ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }