var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Time","code-toggler":""}},[_c('p',[_vm._v("You can change the total time that the notification stays on the screen with the "),_c('code',[_vm._v("time")]),_vm._v(" property. Example: "),_c('code',[_vm._v("time: 4000")])]),_c('vs-alert',{staticClass:"mt-5",attrs:{"color":"primary","icon":"new_releases","active":"true"}},[_c('p',[_vm._v("By default the notifications have a time of "),_c('strong',[_vm._v("2000")]),_vm._v(" (2s)")])]),_c('div',{staticClass:"demo-alignment"},[_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
              title:'Time default',
              text:'Lorem ipsum dolor sit amet consectetur',
              color:'primary',
              iconPack: 'feather',
              icon: 'icon-clock'
              })}}},[_vm._v("Time default ")]),_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){_vm.$vs.notify({
              time:4000,
              title:'Time 4s (4000)',
              text:'Lorem ipsum dolor sit amet consectetur',
              color:'primary',
              iconPack: 'feather',
              icon: 'icon-clock'
              })}}},[_vm._v("Time 4s (4000) ")]),_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){_vm.$vs.notify({
              time:8000,
              title:'Time 8s (8000)',
              text:'Lorem ipsum dolor sit amet consectetur',
              color:'primary',
              iconPack: 'feather',
              icon: 'icon-clock'
              })}}},[_vm._v("Time 8s (8000) ")])],1),_c('template',{slot:"codeContainer"},[_vm._v(" <template> <div class=\"demo-alignment\"> <vs-button @click=\"$vs.notify({ title:'Time default', text:'Lorem ipsum dolor sit amet consectetur', color:'primary', iconPack: 'feather', icon: 'icon-clock' })\" color=\"primary\" type=\"flat\">Time default</vs-button> <vs-button @click=\"$vs.notify({ time:4000, title:'Time 4s (4000)', text:'Lorem ipsum dolor sit amet consectetur', color:'primary', iconPack: 'feather', icon: 'icon-clock' })\" color=\"primary\" type=\"flat\">Time 4s (4000)</vs-button> <vs-button @click=\"$vs.notify({ time:8000, title:'Time 8s (8000)', text:'Lorem ipsum dolor sit amet consectetur', color:'primary', iconPack: 'feather', icon: 'icon-clock' })\" color=\"primary\" type=\"flat\">Time 8s (8000)</vs-button> </div> </template> ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }